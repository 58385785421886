export { onBeforeRoute }

import type { OnBeforeRouteSync } from 'vike/types'

const onBeforeRoute: OnBeforeRouteSync = (pageContext): ReturnType<OnBeforeRouteSync> => {
    console.log("IN ON BEFORE ROUTE")

    const urls = pageContext._pageRoutes.map((item: any) => {
        return item.routeString
    })

    const url = pageContext.urlPathname

    // remove trailing slash
    const cleanedUrl = (url !== "/") ? url.replace(/\/$/, "") : url

    if (!urls.includes(cleanedUrl)) {
        if (!["/error", "/error/"].includes(cleanedUrl)) {
            // throw redirect("/error")

            return {
                pageContext: {
                    is404: true // Only return the property you're adding or changing
                }
            }
        }
    }

    return {
        pageContext: {
            urlOriginal: pageContext.urlOriginal
        }
    }
}